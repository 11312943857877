import styled from 'styled-components';

const Tile = styled.div<{ smallHorizontalPadding?: boolean }>`
  padding: ${({ theme, smallHorizontalPadding }) =>
    smallHorizontalPadding
      ? `${theme.sizes.large} ${theme.sizes.small}`
      : `${theme.sizes.large} ${theme.sizes.large}`};

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadii.normal};
  box-shadow: ${({ theme }) => theme.boxShadows.tile};
`;

export default Tile;
